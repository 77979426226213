.works {
  &_title {
    width: 18%;
    margin: 0 auto 5%;

    @include pc {
      max-width: 240px;
    }

    @include sp {
      width: 45%;
    }
  }

  &_lead {
    width: 54%;
    margin: 0 auto 3%;

    @include pc {
      max-width: 727px;
    }

    @include sp {
      width: 90%;
    }

    svg {
      width: 100%;
    }
  }

  &_text {
    font-size: 14px;
    line-height: 2.28;
    text-align: center;
    color: $white;

    @include sp {
      padding: 0 10%;
      box-sizing: border-box;
      font-size: 16px;
      text-align: left;
    }
  }
}
