.contact {
  &_title {
    width: 18%;
    margin: 0 auto 5%;

    @include pc {
      max-width: 240px;
    }

    @include sp {
      width: 45%;
      margin: 0 auto 10%;
    }
  }

  &_cont {
    width: 59%;
    margin: 0 auto;
    border-top: 1px solid $white;

    @include pc {
      max-width: 800px;
    }

    @include sp {
      width: 90%;
      margin: 10% auto 0;
    }
  }

  &_input {
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
    font-size: 14px;
    color: $black;
    box-shadow: none;
    background-color: $white;
    border: none;
    border-radius: 6px;
    -webkit-appearance: none;

    @include sp {
      font-size: 16px;
    }

    &.error {
      @extend .contact_input;
    }
  }

  &_textarea {
    width: 100%;
    height: 120px;
    padding: 8px;
    box-sizing: border-box;
    font-size: 14px;
    color: $black;
    line-height: 1.6;
    box-shadow: none;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 6px;
    -webkit-appearance: none;
    resize: none;

    @include sp {
      height: 300px;
      font-size: 16px;
    }

    &.error {
      @extend .contact_textarea;
    }
  }
}

.contactInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid $white;

  @include sp {
    display: block;
    font-size: 16px;
  }

  &_head {
    width: 175px;
    padding: 20px 24px;
    box-sizing: border-box;
    color: $white;

    @include sp {
      width: 100%;
      padding: 20px 12px 0;
    }
  }

  &_body {
    padding: 20px 24px;
    flex: 1;

    @include sp {
      width: 100%;
      padding: 20px 12px;
      box-sizing: border-box;
    }

    &--confirm {
      @extend .contactInfo_body;
      color: $white;
    }
  }
}

.contactPrivacy {
  width: 100%;

  &_lead {
    padding: 48px 0 24px;
    font-size: 18px;
    color: $white;
    text-align: center;
  }

  &_cont {
    width: 100%;
    height: 160px;
    padding: 24px;
    box-sizing: border-box;
    background-color: $white;
    border-radius: 6px;
    overflow-y: scroll;

    @include sp {
      height: 240px;
      padding: 16px 20px;
    }
  }

  &_doc {
    font-size: 14px;
    line-height: 1.6;

    & + & {
      margin: 30px 0 0;
    }

    &Inner {
      padding-left: 1em;
      text-indent: -1em;
      display: block;
    }
  }

  &_label {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include sp {
      margin: 30px 0;
    }

    .error {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);

      @include sp {
        width: 100%;
      }
    }
  }

  &_check {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;

    &:checked + .contactPrivacy_checkIco {
      background-color: $white;
      &:after {
        opacity: 1;
      }
    }

    &:checked + label {
      & + .contactPrivacy_checkIco {
        background-color: $white;
        &:after {
          opacity: 1;
        }
      }
    }

    &Ico {
      width: 16px;
      height: 16px;
      margin: 0 10px 0 0;
      display: block;
      border: 1px solid $white;
      border-radius: 3px;
      background-color: transparent;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 4px;
        border-left: 2px solid $black;
        border-bottom: 2px solid $black;
        position: absolute;
        top: calc(50% - 1px);
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        opacity: 0;
      }
    }

    &Text {
      font-size: 14px;
      color: $white;

      @include sp {
        flex: 1;
        font-size: 16px;
        line-height: 1.6;
      }
    }
  }

  &_submit {
    width: 240px;
    margin: 0 auto;
    padding: 12px 0;
    display: block;
    font-size: 14px;
    color: $black;
    background-color: $white;
    border: 2px solid $white;
    border-radius: 3px;
    -webkit-appearance: none;

    @include pc {
      transition: color 0.3s $bezier, background-color 0.3s $bezier;

      &:hover {
        color: $white;
        background-color: transparent;
      }
    }

    @include sp {
      width: 60%;
      margin: 60px auto 0;
      font-size: 16px;
    }

    &--edit {
      @extend .contactPrivacy_submit;
      color: $white;
      background-color: transparent;
      border: 2px solid $white;

      @include pc {
        transition: color 0.3s $bezier, background-color 0.3s $bezier;

        &:hover {
          color: $blackFooter;
          background-color: $white;
        }
      }
    }

    &--single {
      @extend .contactPrivacy_submit;
      margin: 50px auto 0;

      @include sp {
        margin: 45px auto 0;
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .contactPrivacy_doc:last-child {
    margin: 0 0 24px;
  }
}

.error {
  padding: 8px 0 0;
  display: block;
  font-size: 12px;
  color: $red;

  @include sp {
    font-size: 14px;
  }
}

.contactBtns {
  margin: 60px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include sp {
    width: 90%;
    margin: 45px auto;
    justify-content: space-between;
  }

  &_box {
    width: 180px;

    @include sp {
      width: 45%;
    }

    .contactPrivacy_submit {
      width: 100%;
    }

    &:last-child {
      @include pc {
        margin: 0 0 0 45px;
      }
    }
  }
}

.contactComplete {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: $white;

  &_lead {
    font-size: 24px;
  }

  &_text {
    padding: 30px 0;
    font-size: 16px;
    line-height: 2;
  }

  &_btn {
    width: 240px;
    margin: 0 auto;
    padding: 16px 0;
    display: block;
    font-size: 14px;
    color: $black;
    text-align: center;
    background-color: $white;
    border: 2px solid $white;
    border-radius: 3px;
    -webkit-appearance: none;

    @include pc {
      transition: color 0.3s $bezier, background-color 0.3s $bezier;

      &:hover {
        color: $white;
        background-color: transparent;
      }
    }

    @include sp {
      width: 60%;
      font-size: 16px;
    }
  }
}
