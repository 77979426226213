@mixin pc($width: 769px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin sp($width: 768px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@function get_vw($size, $viewport: 768) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10) {
  font-size: $font_size * 1px;
  @include sp {
    font-size: $font_size * 1px;
    font-size: get_vw($font_size);
  }
}
