.header {
  width: 100%;
  padding: 3% 5%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: background-color 0.2s ease;

  @include sp {
    padding: 5%;
  }

  &.is-scroll {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.header_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header_phone {
  width: 280px;

  @include sp {
    max-width: 280px;
    width: calc(100% - 80px);
  }
}

.headerHamburger {
  cursor: pointer;

  @include pc {
    display: none;
  }

  @include sp(900px) {
    width: 40px;
    height: 24px;
    display: block;
    position: fixed;
    right: 5vw;
    top: auto;
    z-index: 9999;
  }

  &.is-active {
    .headerHamburger_list {
      transition-delay: 0.22s;
      background-color: transparent !important;

      &:before {
        top: 0;
        transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
          transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 10px, 0) rotate(45deg);
        background-color: $white;
      }

      &:after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 10px, 0) rotate(-45deg);
        background-color: $white;
      }
    }
  }
}

.headerHamburger_list {
  position: absolute;
  width: 40px;
  height: 4px;
  top: 2px;
  transition: background-color 0s linear 0.13s;
  border-radius: 4px;
  background-color: $white;

  @include sp {
    width: 100%;
    height: 2px;
  }

  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background-color: $white;
    position: absolute;
    top: 10px;
    transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    @include sp {
      width: 100%;
      height: 2px;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background-color: $white;
    position: absolute;
    top: 20px;
    transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    @include sp {
      width: 100%;
      height: 2px;
    }
  }
}

.headerNav {
  flex: 1;

  @include sp(900px) {
    width: 100vw;
    height: 100vh;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($black, 0.9);
    z-index: -9999;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  &.is-active {
    @include sp(900px) {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      z-index: 9998;
      pointer-events: auto;

      .headerNav_cont {
        flex-flow: column;
      }

      .headerNav_list {
        margin: 40px 0 0;

        &:first-child {
          margin: 0;
        }
      }

      .headerNav_anchor {
        @include fz_vw(24);

        @include sp {
          @include fz_vw(32);
        }
      }
    }
  }
}

.headerNav_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerNav_list {
  margin: 0 0 0 5%;

  @include pc {
    margin: 0 0 0 5%;
  }

  @include sp(900px) {
    opacity: 0;
    transform: translateX(-30px);
    transition: transform 0.3s $bezier 0.1s, opacity 0.3s ease 0.1s;
  }

  &.is-active {
    opacity: 1;
    transform: translateX(0);
  }

  &:first-child {
    @include pc {
      margin: 0;
    }
  }
}

.headerNav_anchor {
  font-family: $din;
  width: auto;
  height: 100%;
  display: block;
  @include fz_vw(18);
  color: $white;
  letter-spacing: 0.12em;
  position: relative;

  &:after {
    content: '';
    width: 0;
    height: 2px;
    background-color: $white;
    position: absolute;
    left: 0;
    bottom: -10px;

    @include pc {
      transition: width $bezier 0.3s;
    }
  }

  &:hover {
    &:after {
      @include pc {
        width: 100%;
      }
    }
  }
}
