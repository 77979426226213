.about {
  &_title {
    width: 18%;
    margin: 0 auto 5%;

    @include pc {
      max-width: 240px;
    }

    @include sp {
      width: 45%;
    }
  }

  &_cont {
    width: 65%;
    margin: 0 auto;

    @include pc {
      max-width: 880px;
      display: flex;
    }

    @include sp {
      width: 90%;
      margin: 10% auto 0;
    }
  }

  &_box {
    width: 50%;

    @include sp {
      width: 100%;
    }

    & + & {
      @include sp {
        margin: 5% 0 0;
      }
    }
  }
}

.aboutInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.6;
  color: $white;

  @include sp {
    font-size: 16px;
  }

  & + & {
    margin: 5% 0 0;
  }

  &_head {
    width: 100px;

    @include sp {
      padding: 0 0 0 24px;
    }
  }

  &_body {
    flex: 1;
  }
}
