.pager {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 0;

  @include sp {
    display: none;
  }

  li {
    display: block;
    margin: 20px;
    a {
      display: block;
      @include fz_vw(16);
      color: $white;
      text-align: center;
      position: relative;

      &:before {
        content: attr(data-text);
        opacity: 0;
        position: absolute;
        top: 50%;
        right: calc(100% + 17px);
        transform: translateY(-50%);
      }

      .pager_num {
        display: block;
      }

      .pager_active {
        display: none;
      }
    }
  }
  .is-current {
    a {
      &:before {
        opacity: 1;
      }

      .pager_num {
        display: none;
      }

      .pager_active {
        display: block;
      }
    }
  }
}
