body {
  font-family: 'Noto Sans JP', sans-serif;
}

img {
  width: 100%;
  display: block;
}

.pc {
  @include pc {
    display: block;
  }

  @include sp {
    display: none !important;
  }
}

.sp {
  @include pc {
    display: none !important;
  }

  @include sp {
    display: block;
  }
}
