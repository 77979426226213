@charset "UTF-8";

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

@font-face {
  font-family: 'din';
  src: url("../font/din1451alt.woff") format("woff");
}

body {
  font-family: 'Noto Sans JP', sans-serif;
}

img {
  width: 100%;
  display: block;
}

@media screen and (min-width: 769px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .sp {
    display: block;
  }
}

.section--works, .section--about, .section--recruit, .section--contact, .section--complete {
  padding: 160px 0;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .section--works, .section--about, .section--recruit, .section--contact, .section--complete {
    padding: 28% 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.section {
  width: 100%;
  position: relative;
}

.section.is-show .section-content {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .section--top {
    height: 100vh;
  }
}

.section--works {
  background: url("../img/bg_works.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .section--works {
    background: url("../img/bg_works.jpg") no-repeat 65% 50%;
    background-size: cover;
  }
}

.section--about {
  background: url("../img/bg_about.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (min-width: 769px) {
  .section--about {
    padding-top: 140px;
  }
}

@media screen and (max-width: 768px) {
  .section--about {
    background: url("../img/bg_about.jpg") no-repeat 72% 50%;
    background-size: cover;
  }
}

.section--recruit {
  background: url("../img/bg_recruit.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .section--recruit {
    background: url("../img/bg_recruit.jpg") no-repeat 40% 50%;
    background-size: cover;
  }
}

.section--contact {
  padding-bottom: 0;
  background: url("../img/bg_contact.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .section--contact {
    padding-bottom: 0;
    display: block;
  }
}

.section--complete {
  width: 100vw;
  height: 100vh;
  background: url("../img/bg_contact.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .section--complete {
    display: block;
  }
}

.header {
  width: 100%;
  padding: 3% 5%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: background-color 0.2s ease;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 5%;
  }
}

.header.is-scroll {
  background-color: rgba(0, 0, 0, 0.5);
}

.header_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header_phone {
  width: 280px;
}

@media screen and (max-width: 768px) {
  .header_phone {
    max-width: 280px;
    width: calc(100% - 80px);
  }
}

.headerHamburger {
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .headerHamburger {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .headerHamburger {
    width: 40px;
    height: 24px;
    display: block;
    position: fixed;
    right: 5vw;
    top: auto;
    z-index: 9999;
  }
}

.headerHamburger.is-active .headerHamburger_list {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.headerHamburger.is-active .headerHamburger_list:before {
  top: 0;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(45deg);
  background-color: #fff;
}

.headerHamburger.is-active .headerHamburger_list:after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(-45deg);
  background-color: #fff;
}

.headerHamburger_list {
  position: absolute;
  width: 40px;
  height: 4px;
  top: 2px;
  transition: background-color 0s linear 0.13s;
  border-radius: 4px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .headerHamburger_list {
    width: 100%;
    height: 2px;
  }
}

.headerHamburger_list:before {
  content: '';
  display: block;
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 10px;
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

@media screen and (max-width: 768px) {
  .headerHamburger_list:before {
    width: 100%;
    height: 2px;
  }
}

.headerHamburger_list:after {
  content: '';
  display: block;
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 20px;
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

@media screen and (max-width: 768px) {
  .headerHamburger_list:after {
    width: 100%;
    height: 2px;
  }
}

.headerNav {
  flex: 1;
}

@media screen and (max-width: 900px) {
  .headerNav {
    width: 100vw;
    height: 100vh;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: -9999;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
}

@media screen and (max-width: 900px) {
  .headerNav.is-active {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 9998;
    pointer-events: auto;
  }
  .headerNav.is-active .headerNav_cont {
    flex-flow: column;
  }
  .headerNav.is-active .headerNav_list {
    margin: 40px 0 0;
  }
  .headerNav.is-active .headerNav_list:first-child {
    margin: 0;
  }
  .headerNav.is-active .headerNav_anchor {
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) and (max-width: 768px) {
  .headerNav.is-active .headerNav_anchor {
    font-size: 24px;
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 900px) and (max-width: 768px) {
  .headerNav.is-active .headerNav_anchor {
    font-size: 32px;
  }
}

@media screen and (max-width: 900px) and (max-width: 768px) and (max-width: 768px) {
  .headerNav.is-active .headerNav_anchor {
    font-size: 32px;
    font-size: 4.16667vw;
  }
}

.headerNav_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerNav_list {
  margin: 0 0 0 5%;
}

@media screen and (min-width: 769px) {
  .headerNav_list {
    margin: 0 0 0 5%;
  }
}

@media screen and (max-width: 900px) {
  .headerNav_list {
    opacity: 0;
    transform: translateX(-30px);
    transition: transform 0.3s cubic-bezier(0.5, 0, 0, 1) 0.1s, opacity 0.3s ease 0.1s;
  }
}

.headerNav_list.is-active {
  opacity: 1;
  transform: translateX(0);
}

@media screen and (min-width: 769px) {
  .headerNav_list:first-child {
    margin: 0;
  }
}

.headerNav_anchor {
  font-family: "din";
  width: auto;
  height: 100%;
  display: block;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.12em;
  position: relative;
}

@media screen and (max-width: 768px) {
  .headerNav_anchor {
    font-size: 18px;
    font-size: 2.34375vw;
  }
}

.headerNav_anchor:after {
  content: '';
  width: 0;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: -10px;
}

@media screen and (min-width: 769px) {
  .headerNav_anchor:after {
    transition: width cubic-bezier(0.5, 0, 0, 1) 0.3s;
  }
}

@media screen and (min-width: 769px) {
  .headerNav_anchor:hover:after {
    width: 100%;
  }
}

.footer {
  margin: 130px 0 0;
  padding: 60px 0 24px;
  background-color: #444;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 0 0 24px;
  }
}

.footer_cont {
  width: 59%;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .footer_cont {
    max-width: 800px;
  }
}

@media screen and (max-width: 768px) {
  .footer_cont {
    width: 100%;
    margin: 10% 0 0;
  }
}

.footer_box {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .footer_box {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 769px) {
  .footer_list {
    margin: 0 0 0 40px;
  }
  .footer_list:first-child {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .footer_list {
    width: 50%;
    box-sizing: border-box;
    border-bottom: 1px solid #fff;
  }
  .footer_list:nth-child(even) {
    border-left: 1px solid #fff;
  }
  .footer_list:last-child {
    width: 100%;
  }
}

.footer_anchor {
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.08em;
  position: relative;
}

@media screen and (max-width: 768px) {
  .footer_anchor {
    width: 100%;
    padding: 24px 0;
    display: block;
    text-align: center;
    font-size: 16px;
  }
}

.footer_anchor:after {
  content: '';
  width: 0;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: -5px;
}

@media screen and (min-width: 769px) {
  .footer_anchor:after {
    transition: width cubic-bezier(0.5, 0, 0, 1) 0.3s;
  }
}

@media screen and (min-width: 769px) {
  .footer_anchor:hover:after {
    width: 100%;
  }
}

.footer_sns {
  margin: 40px 0 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.footer_snsText {
  padding: 0 0 16px;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.08em;
}

.footer_snsItem {
  width: 100%;
  max-width: 32px;
  display: block;
}

@media screen and (min-width: 769px) {
  .footer_snsItem {
    transition: opacity 0.3s ease;
  }
  .footer_snsItem:hover {
    opacity: 0.7;
  }
}

.footer_copy {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.12em;
  text-align: center;
}

.pager {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 0;
}

@media screen and (max-width: 768px) {
  .pager {
    display: none;
  }
}

.pager li {
  display: block;
  margin: 20px;
}

.pager li a {
  display: block;
  font-size: 16px;
  color: #fff;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 768px) {
  .pager li a {
    font-size: 16px;
    font-size: 2.08333vw;
  }
}

.pager li a:before {
  content: attr(data-text);
  opacity: 0;
  position: absolute;
  top: 50%;
  right: calc(100% + 17px);
  transform: translateY(-50%);
}

.pager li a .pager_num {
  display: block;
}

.pager li a .pager_active {
  display: none;
}

.pager .is-current a:before {
  opacity: 1;
}

.pager .is-current a .pager_num {
  display: none;
}

.pager .is-current a .pager_active {
  display: block;
}

.slider {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slider_cont {
  width: 100%;
  height: 100%;
}

.sliderMv {
  width: 31%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 769px) {
  .sliderMv {
    max-width: 420px;
  }
}

@media screen and (max-width: 768px) {
  .sliderMv {
    width: 60%;
  }
}

.sliderMv_lead {
  padding: 0 0 30px;
}

@media screen and (max-width: 768px) {
  .sliderMv_lead {
    padding: 0 0 20px;
  }
}

.sliderMv_pager {
  width: auto;
  height: 3.4vw;
  margin: 30px 0 0;
  opacity: 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .sliderMv_pager {
    height: 3.5vh;
    margin: 20px 0 0;
  }
}

.sliderMv_pager.is-active {
  animation: imageBlur 0.2s both;
}

.sliderMv_pager img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes imageBlur {
  from {
    opacity: 0;
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -ms-filter: blur(15px);
    -o-filter: blur(15px);
    filter: blur(15px);
  }
  to {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
  }
}

.sliderImg, .sliderImg--01, .sliderImg--02, .sliderImg--03, .sliderImg--04 {
  width: 100%;
  height: 100vh;
}

.sliderImg--01 {
  background: url("../img/mv01.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .sliderImg--01 {
    background: url("../img/mv01_sp.jpg") no-repeat 25% 50%;
    background-size: cover;
  }
}

.sliderImg--02 {
  background: url("../img/mv02.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .sliderImg--02 {
    background: url("../img/mv02_sp.jpg") no-repeat 0 50%;
    background-size: cover;
  }
}

.sliderImg--03 {
  background: url("../img/mv03.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .sliderImg--03 {
    background: url("../img/mv03_sp.jpg") no-repeat center;
    background-size: cover;
  }
}

.sliderImg--04 {
  background: url("../img/mv04.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .sliderImg--04 {
    background: url("../img/mv04_sp.jpg") no-repeat center;
    background-size: cover;
  }
}

.sliderScroll {
  position: absolute;
  left: 50%;
  bottom: 40px;
  z-index: 2;
  transform: translateX(-50%);
}

.sliderScroll_frame {
  width: 24px;
  height: 44px;
  margin: 0 auto;
  border: 2px solid #fff;
  border-radius: 50px;
  position: relative;
}

.sliderScroll_point {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  animation: sdb 2s infinite;
}

@keyframes sdb {
  0% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(-50%, 30px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.sliderScroll_text {
  margin: 12px 0 0;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.08em;
}

/* swiper custom */
.swiper-button-next {
  width: 4%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translate(0, -50%);
}

@media screen and (min-width: 769px) {
  .swiper-button-next {
    max-width: 41px;
    height: 120px;
    right: 60px;
    transition: transform 0.3s cubic-bezier(0.5, 0, 0, 1);
  }
}

@media screen and (max-width: 768px) {
  .swiper-button-next {
    width: 6%;
    height: 6vh;
    right: 15px;
  }
}

@media screen and (min-width: 769px) {
  .swiper-button-next:hover {
    transform: translate(10px, -50%);
  }
}

.swiper-button-next:after {
  display: none;
}

.swiper-button-prev {
  width: 4%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translate(0, -50%);
}

@media screen and (min-width: 769px) {
  .swiper-button-prev {
    max-width: 41px;
    height: 120px;
    left: 60px;
    transition: transform 0.3s cubic-bezier(0.5, 0, 0, 1);
  }
}

@media screen and (max-width: 768px) {
  .swiper-button-prev {
    width: 6%;
    height: 6vh;
    left: 15px;
  }
}

@media screen and (min-width: 769px) {
  .swiper-button-prev:hover {
    transform: translate(-10px, -50%);
  }
}

.swiper-button-prev:after {
  display: none;
}

.swiper-pagination {
  display: none;
}

/* end swiper custom */
.slick-list {
  height: 100%;
}

.slick-track {
  height: 100%;
}

.slick-slide > div {
  height: 100%;
}

.slick-slide > div > li {
  height: 100%;
}

.slick-slide > div > li > a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.slick-slide > div > li > a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-height: 100%;
}

.works_title {
  width: 18%;
  margin: 0 auto 5%;
}

@media screen and (min-width: 769px) {
  .works_title {
    max-width: 240px;
  }
}

@media screen and (max-width: 768px) {
  .works_title {
    width: 45%;
  }
}

.works_lead {
  width: 54%;
  margin: 0 auto 3%;
}

@media screen and (min-width: 769px) {
  .works_lead {
    max-width: 727px;
  }
}

@media screen and (max-width: 768px) {
  .works_lead {
    width: 90%;
  }
}

.works_lead svg {
  width: 100%;
}

.works_text {
  font-size: 14px;
  line-height: 2.28;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .works_text {
    padding: 0 10%;
    box-sizing: border-box;
    font-size: 16px;
    text-align: left;
  }
}

.about_title {
  width: 18%;
  margin: 0 auto 5%;
}

@media screen and (min-width: 769px) {
  .about_title {
    max-width: 240px;
  }
}

@media screen and (max-width: 768px) {
  .about_title {
    width: 45%;
  }
}

.about_cont {
  width: 65%;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .about_cont {
    max-width: 880px;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .about_cont {
    width: 90%;
    margin: 10% auto 0;
  }
}

.about_box {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .about_box {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .about_box + .about_box {
    margin: 5% 0 0;
  }
}

.aboutInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.6;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .aboutInfo {
    font-size: 16px;
  }
}

.aboutInfo + .aboutInfo {
  margin: 5% 0 0;
}

.aboutInfo_head {
  width: 100px;
}

@media screen and (max-width: 768px) {
  .aboutInfo_head {
    padding: 0 0 0 24px;
  }
}

.aboutInfo_body {
  flex: 1;
}

.recruit_title {
  width: 18%;
  margin: 0 auto 5%;
}

@media screen and (min-width: 769px) {
  .recruit_title {
    max-width: 240px;
  }
}

@media screen and (max-width: 768px) {
  .recruit_title {
    width: 45%;
    margin: 0 auto 10%;
  }
}

.recruit_cont {
  width: 59%;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .recruit_cont {
    max-width: 800px;
  }
}

@media screen and (max-width: 768px) {
  .recruit_cont {
    width: 90%;
  }
}

.recruit_lead {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .recruit_lead {
    height: 40px;
    background: url(../img/recruit_lead.png) no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .recruit_lead img {
    display: none;
  }
}

.recruit_foot {
  padding: 20px 0;
  font-size: 14px;
  color: #fff;
  line-height: 1.7;
  text-align: center;
  border: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .recruit_foot {
    font-size: 16px;
  }
}

.recruitInfo {
  display: flex;
  font-size: 14px;
  line-height: 1.7;
  color: #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .recruitInfo {
    font-size: 16px;
  }
}

.recruitInfo + .recruitInfo {
  border-top: 1px solid #fff;
}

.recruitInfo_head {
  width: 140px;
  padding: 20px 18px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .recruitInfo_head {
    width: 40%;
    padding: 20px 12px;
  }
}

.recruitInfo_body {
  padding: 20px 18px;
  box-sizing: border-box;
  flex: 1;
}

.contact_title {
  width: 18%;
  margin: 0 auto 5%;
}

@media screen and (min-width: 769px) {
  .contact_title {
    max-width: 240px;
  }
}

@media screen and (max-width: 768px) {
  .contact_title {
    width: 45%;
    margin: 0 auto 10%;
  }
}

.contact_cont {
  width: 59%;
  margin: 0 auto;
  border-top: 1px solid #fff;
}

@media screen and (min-width: 769px) {
  .contact_cont {
    max-width: 800px;
  }
}

@media screen and (max-width: 768px) {
  .contact_cont {
    width: 90%;
    margin: 10% auto 0;
  }
}

.contact_input, .contact_input.error {
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  color: #000;
  box-shadow: none;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  -webkit-appearance: none;
}

@media screen and (max-width: 768px) {
  .contact_input, .contact_input.error {
    font-size: 16px;
  }
}

.contact_textarea, .contact_textarea.error {
  width: 100%;
  height: 120px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  color: #000;
  line-height: 1.6;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  -webkit-appearance: none;
  resize: none;
}

@media screen and (max-width: 768px) {
  .contact_textarea, .contact_textarea.error {
    height: 300px;
    font-size: 16px;
  }
}

.contactInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .contactInfo {
    display: block;
    font-size: 16px;
  }
}

.contactInfo_head {
  width: 175px;
  padding: 20px 24px;
  box-sizing: border-box;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .contactInfo_head {
    width: 100%;
    padding: 20px 12px 0;
  }
}

.contactInfo_body, .contactInfo_body--confirm {
  padding: 20px 24px;
  flex: 1;
}

@media screen and (max-width: 768px) {
  .contactInfo_body, .contactInfo_body--confirm {
    width: 100%;
    padding: 20px 12px;
    box-sizing: border-box;
  }
}

.contactInfo_body--confirm {
  color: #fff;
}

.contactPrivacy {
  width: 100%;
}

.contactPrivacy_lead {
  padding: 48px 0 24px;
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.contactPrivacy_cont {
  width: 100%;
  height: 160px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  overflow-y: scroll;
}

@media screen and (max-width: 768px) {
  .contactPrivacy_cont {
    height: 240px;
    padding: 16px 20px;
  }
}

.contactPrivacy_doc {
  font-size: 14px;
  line-height: 1.6;
}

.contactPrivacy_doc + .contactPrivacy_doc {
  margin: 30px 0 0;
}

.contactPrivacy_docInner {
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}

.contactPrivacy_label {
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 768px) {
  .contactPrivacy_label {
    margin: 30px 0;
  }
}

.contactPrivacy_label .error {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .contactPrivacy_label .error {
    width: 100%;
  }
}

.contactPrivacy_check {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}

.contactPrivacy_check:checked + .contactPrivacy_checkIco {
  background-color: #fff;
}

.contactPrivacy_check:checked + .contactPrivacy_checkIco:after {
  opacity: 1;
}

.contactPrivacy_check:checked + label + .contactPrivacy_checkIco {
  background-color: #fff;
}

.contactPrivacy_check:checked + label + .contactPrivacy_checkIco:after {
  opacity: 1;
}

.contactPrivacy_checkIco {
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
  display: block;
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: transparent;
  position: relative;
}

.contactPrivacy_checkIco:after {
  content: '';
  display: block;
  width: 8px;
  height: 4px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  opacity: 0;
}

.contactPrivacy_checkText {
  font-size: 14px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .contactPrivacy_checkText {
    flex: 1;
    font-size: 16px;
    line-height: 1.6;
  }
}

.contactPrivacy_submit, .contactPrivacy_submit--edit, .contactPrivacy_submit--single {
  width: 240px;
  margin: 0 auto;
  padding: 12px 0;
  display: block;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  -webkit-appearance: none;
}

@media screen and (min-width: 769px) {
  .contactPrivacy_submit, .contactPrivacy_submit--edit, .contactPrivacy_submit--single {
    transition: color 0.3s cubic-bezier(0.5, 0, 0, 1), background-color 0.3s cubic-bezier(0.5, 0, 0, 1);
  }
  .contactPrivacy_submit:hover, .contactPrivacy_submit--edit:hover, .contactPrivacy_submit--single:hover {
    color: #fff;
    background-color: transparent;
  }
}

@media screen and (max-width: 768px) {
  .contactPrivacy_submit, .contactPrivacy_submit--edit, .contactPrivacy_submit--single {
    width: 60%;
    margin: 60px auto 0;
    font-size: 16px;
  }
}

.contactPrivacy_submit--edit {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
}

@media screen and (min-width: 769px) {
  .contactPrivacy_submit--edit {
    transition: color 0.3s cubic-bezier(0.5, 0, 0, 1), background-color 0.3s cubic-bezier(0.5, 0, 0, 1);
  }
  .contactPrivacy_submit--edit:hover {
    color: #444;
    background-color: #fff;
  }
}

.contactPrivacy_submit--single {
  margin: 50px auto 0;
}

@media screen and (max-width: 768px) {
  .contactPrivacy_submit--single {
    margin: 45px auto 0;
  }
}

@media all and (-ms-high-contrast: none) {
  .contactPrivacy_doc:last-child {
    margin: 0 0 24px;
  }
}

.error {
  padding: 8px 0 0;
  display: block;
  font-size: 12px;
  color: #ed4130;
}

@media screen and (max-width: 768px) {
  .error {
    font-size: 14px;
  }
}

.contactBtns {
  margin: 60px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .contactBtns {
    width: 90%;
    margin: 45px auto;
    justify-content: space-between;
  }
}

.contactBtns_box {
  width: 180px;
}

@media screen and (max-width: 768px) {
  .contactBtns_box {
    width: 45%;
  }
}

.contactBtns_box .contactPrivacy_submit, .contactBtns_box .contactPrivacy_submit--edit, .contactBtns_box .contactPrivacy_submit--single {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .contactBtns_box:last-child {
    margin: 0 0 0 45px;
  }
}

.contactComplete {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.contactComplete_lead {
  font-size: 24px;
}

.contactComplete_text {
  padding: 30px 0;
  font-size: 16px;
  line-height: 2;
}

.contactComplete_btn {
  width: 240px;
  margin: 0 auto;
  padding: 16px 0;
  display: block;
  font-size: 14px;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  -webkit-appearance: none;
}

@media screen and (min-width: 769px) {
  .contactComplete_btn {
    transition: color 0.3s cubic-bezier(0.5, 0, 0, 1), background-color 0.3s cubic-bezier(0.5, 0, 0, 1);
  }
  .contactComplete_btn:hover {
    color: #fff;
    background-color: transparent;
  }
}

@media screen and (max-width: 768px) {
  .contactComplete_btn {
    width: 60%;
    font-size: 16px;
  }
}
