@charset "UTF-8";

// font
@font-face {
  font-family: 'din';
  src: url('../font/din1451alt.woff') format('woff');
}

$din: 'din';

// transition
$bezier: cubic-bezier(0.5, 0, 0, 1);

// color
$white: #fff;
$black: #000;
$blackFooter: #444;
$red: #ed4130;
