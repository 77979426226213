.footer {
  margin: 130px 0 0;
  padding: 60px 0 24px;
  background-color: $blackFooter;

  @include sp {
    padding: 0 0 24px;
  }

  &_cont {
    width: 59%;
    margin: 0 auto;

    @include pc {
      max-width: 800px;
    }

    @include sp {
      width: 100%;
      margin: 10% 0 0;
    }
  }

  &_box {
    width: 100%;
    display: flex;
    justify-content: center;

    @include sp {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &_list {
    @include pc {
      margin: 0 0 0 40px;

      &:first-child {
        margin: 0;
      }
    }

    @include sp {
      width: 50%;
      box-sizing: border-box;
      border-bottom: 1px solid $white;

      &:nth-child(even) {
        border-left: 1px solid $white;
      }

      &:last-child {
        width: 100%;
      }
    }
  }

  &_anchor {
    font-size: 18px;
    color: $white;
    letter-spacing: 0.08em;
    position: relative;

    @include sp {
      width: 100%;
      padding: 24px 0;
      display: block;
      text-align: center;
      font-size: 16px;
    }

    &:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: $white;
      position: absolute;
      left: 0;
      bottom: -5px;

      @include pc {
        transition: width $bezier 0.3s;
      }
    }

    &:hover {
      &:after {
        @include pc {
          width: 100%;
        }
      }
    }
  }

  &_sns {
    margin: 40px 0 60px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &Text {
      padding: 0 0 16px;
      font-size: 14px;
      color: $white;
      letter-spacing: 0.08em;
    }

    &Item {
      width: 100%;
      max-width: 32px;
      display: block;

      @include pc {
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &_copy {
    font-size: 12px;
    color: $white;
    letter-spacing: 0.12em;
    text-align: center;
  }
}
