.recruit {
  &_title {
    width: 18%;
    margin: 0 auto 5%;

    @include pc {
      max-width: 240px;
    }

    @include sp {
      width: 45%;
      margin: 0 auto 10%;
    }
  }

  &_cont {
    width: 59%;
    margin: 0 auto;

    @include pc {
      max-width: 800px;
    }

    @include sp {
      width: 90%;
    }
  }

  &_lead {
    width: 100%;

    @include sp {
      height: 40px;
      background: url(../img/recruit_lead.png) no-repeat center;
      background-size: cover;
    }

    img {
      @include sp {
        display: none;
      }
    }
  }

  &_foot {
    padding: 20px 0;
    font-size: 14px;
    color: $white;
    line-height: 1.7;
    text-align: center;
    border: 1px solid $white;

    @include sp {
      font-size: 16px;
    }
  }
}

.recruitInfo {
  display: flex;
  font-size: 14px;
  line-height: 1.7;
  color: $white;
  border-right: 1px solid $white;
  border-left: 1px solid $white;

  @include sp {
    font-size: 16px;
  }

  & + & {
    border-top: 1px solid $white;
  }

  &_head {
    width: 140px;
    padding: 20px 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $white;

    @include sp {
      width: 40%;
      padding: 20px 12px;
    }
  }

  &_body {
    padding: 20px 18px;
    box-sizing: border-box;
    flex: 1;
  }
}
