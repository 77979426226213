.slider {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slider_cont {
  width: 100%;
  height: 100%;
}

.sliderMv {
  width: 31%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  @include pc {
    max-width: 420px;
  }

  @include sp {
    width: 60%;
  }
}

.sliderMv_lead {
  padding: 0 0 30px;

  @include sp {
    padding: 0 0 20px;
  }
}

.sliderMv_pager {
  width: auto;
  height: 3.4vw;
  margin: 30px 0 0;
  opacity: 0;
  position: relative;

  @include sp {
    height: 3.5vh;
    margin: 20px 0 0;
  }

  &.is-active {
    animation: imageBlur 0.2s both;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes imageBlur {
  from {
    opacity: 0;
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -ms-filter: blur(15px);
    -o-filter: blur(15px);
    filter: blur(15px);
  }

  to {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
  }
}

.sliderImg {
  width: 100%;
  height: 100vh;
  &--01 {
    @extend .sliderImg;
    background: url('../img/mv01.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      background: url('../img/mv01_sp.jpg') no-repeat 25% 50%;
      background-size: cover;
    }
  }

  &--02 {
    @extend .sliderImg;
    background: url('../img/mv02.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      background: url('../img/mv02_sp.jpg') no-repeat 0 50%;
      background-size: cover;
    }
  }

  &--03 {
    @extend .sliderImg;
    background: url('../img/mv03.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      background: url('../img/mv03_sp.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &--04 {
    @extend .sliderImg;
    background: url('../img/mv04.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      background: url('../img/mv04_sp.jpg') no-repeat center;
      background-size: cover;
    }
  }
}

.sliderScroll {
  position: absolute;
  left: 50%;
  bottom: 40px;
  z-index: 2;
  transform: translateX(-50%);
}

.sliderScroll_frame {
  width: 24px;
  height: 44px;
  margin: 0 auto;
  border: 2px solid $white;
  border-radius: 50px;
  position: relative;
}

.sliderScroll_point {
  width: 8px;
  height: 8px;
  background-color: $white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  animation: sdb 2s infinite;
}

@keyframes sdb {
  0% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(-50%, 30px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.sliderScroll_text {
  margin: 12px 0 0;
  font-size: 14px;
  color: $white;
  letter-spacing: 0.08em;
}

/* swiper custom */
.swiper-button-next {
  width: 4%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translate(0, -50%);

  @include pc {
    max-width: 41px;
    height: 120px;
    right: 60px;
    transition: transform 0.3s $bezier;
  }

  @include sp {
    width: 6%;
    height: 6vh;
    right: 15px;
  }

  &:hover {
    @include pc {
      transform: translate(10px, -50%);
    }
  }

  &:after {
    display: none;
  }
}

.swiper-button-prev {
  width: 4%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translate(0, -50%);

  @include pc {
    max-width: 41px;
    height: 120px;
    left: 60px;
    transition: transform 0.3s $bezier;
  }

  @include sp {
    width: 6%;
    height: 6vh;
    left: 15px;
  }

  &:hover {
    @include pc {
      transform: translate(-10px, -50%);
    }
  }

  &:after {
    display: none;
  }
}

.swiper-pagination {
  display: none;
}
/* end swiper custom */

.slick-list {
  height: 100%;
}

.slick-track {
  height: 100%;
}

.slick-slide > div {
  height: 100%;

  & > li {
    height: 100%;

    & > a {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        max-height: 100%;
      }
    }
  }
}
