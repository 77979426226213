%section {
  padding: 160px 0;
  box-sizing: border-box;

  @include sp {
    padding: 28% 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.section {
  width: 100%;
  position: relative;

  &.is-show {
    .section-content {
      opacity: 1;
    }
  }

  &--top {
    @include sp {
      height: 100vh;
    }
  }

  &--works {
    @extend %section;
    background: url('../img/bg_works.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      background: url('../img/bg_works.jpg') no-repeat 65% 50%;
      background-size: cover;
    }
  }

  &--about {
    @extend %section;
    background: url('../img/bg_about.jpg') no-repeat center;
    background-size: cover;

    @include pc {
      padding-top: 140px;
    }

    @include sp {
      background: url('../img/bg_about.jpg') no-repeat 72% 50%;
      background-size: cover;
    }
  }

  &--recruit {
    @extend %section;
    background: url('../img/bg_recruit.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      background: url('../img/bg_recruit.jpg') no-repeat 40% 50%;
      background-size: cover;
    }
  }

  &--contact {
    @extend %section;
    padding-bottom: 0;
    background: url('../img/bg_contact.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      padding-bottom: 0;
      display: block;
    }
  }

  &--complete {
    @extend %section;
    width: 100vw;
    height: 100vh;
    background: url('../img/bg_contact.jpg') no-repeat center;
    background-size: cover;

    @include sp {
      display: block;
    }
  }
}
